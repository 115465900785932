.docx_tablenormal {
  width: initial !important;
}

.th_border::after {
  content: '  ';
  border: 1px solid white;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: scaleY(0.49999999);
  height: 30px;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.pathogenic {
  background-color: rgb(254, 130, 138);
}

.likelyBenign {
  background-color: rgb(254, 196, 210);
}

.drugResponse {
  background-color: rgb(186, 204, 147);
}

.uncertainSignificance {
  background-color: rgb(240, 239, 240);
}

.likelyBenign {
  background-color: rgb(134, 169, 209);
}

.benign {
  background-color: rgb(103, 142, 197);
}

.lossOfFunction {
  background-color: rgb(246, 209, 139);
}
