.tr {
  position: relative;
}
.tr::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  transform: scaleY(0.49999);
}

.cursor-pointer {
  cursor: pointer;
}
.ant-dropdown {
  z-index: 1999;
}
.cs-message--outgoing .cs-message__content {
  background-color: rgb(221, 217, 255);
  font-size: 1rem;
}
.cs-message--incoming .cs-message__content {
  background-color: white;
  border: 1px solid #c6e3fa;
  font-size: 1rem;
}
.cs-message-list .cs-typing-indicator {
  background-color: rgb(245, 246, 249);
}
.cs-message-list {
  background-color: rgb(245, 246, 249);
}
.cs-chat-container .cs-message-input {
  background-color: rgb(245, 246, 249);
}
.cs-message-input__content-editor-wrapper {
  border: 1px solid #ccc;
  background-color: white;
}
.cs-message-input__content-editor {
  background-color: white;
}

.g6-tooltip {
  border-radius: 6px;
  color: #fff;
  background-color: #000;
  padding: 2px 8px;
  text-align: left;
  font-size: 18px;
}

.slick-arrow {
  color: black !important;
}
.ant-descriptions-item-label {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.MuiDataGrid-virtualScroller {
  min-height: 50px !important;
}

.ant-message {
  z-index: 999999 !important;
}

.ant-descriptions-item-content > span {
  font-size: 15px;
}

/* .td {
  position: relative;
}
.td::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  transform: scaleX(0.49999999);
}

.td::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  transform: scaleY(0.49999999);
} */
